import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../NavBar/Navbar";
import Modal from "react-modal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Zoom } from "swiper/modules";
import DOMPurify from "dompurify";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "./style/CullarVega.css";

import privatePoolAzulejoImage5 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasCinco.jpeg";
import privatePoolAzulejoImage6 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinaSeis.jpeg";
import privatePoolAzulejoImage7 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasSiete.jpeg";
import privatePoolAzulejoImage9 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasNueve.jpeg";
import privatePoolAzulejoImage10 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasDiez.jpeg";
import privatePoolAzulejoImage11 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasOnce.jpeg";
import privatePoolAzulejoImage12 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasDoce.jpeg";
import privatePoolAzulejoImage13 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasTrece.jpeg";
import privatePoolAzulejoImage14 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasCatorce.jpeg";
import privatePoolAzulejoImage15 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasQuince.jpeg";
import privatePoolAzulejoImage16 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/pisicinasDieciseis.jpeg";
import privatePoolAzulejoImage17 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasDiecisiete.jpeg";
import privatePoolAzulejoImage18 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/pisicinasDieciocho.jpeg";
import privatePoolAzulejoImage19 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasDiecinueve.jpeg";
import privatePoolAzulejoImage20 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasVeinte.jpeg";

import azulejoPorcelamicoVideo from "../../../assets/videos/videos-piscinas-azulejo-porcelamico/azulejoPorcelamico.mp4";

const images = [
  privatePoolAzulejoImage5,
  privatePoolAzulejoImage6,
  privatePoolAzulejoImage7,
  privatePoolAzulejoImage9,
  privatePoolAzulejoImage10,
  privatePoolAzulejoImage11,
  privatePoolAzulejoImage12,
  privatePoolAzulejoImage13,
  privatePoolAzulejoImage14,
  privatePoolAzulejoImage15,
  privatePoolAzulejoImage16,
  privatePoolAzulejoImage17,
  privatePoolAzulejoImage18,
  privatePoolAzulejoImage19,
  privatePoolAzulejoImage20,
];

const CullarVegaSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [showScrollArrow, setShowScrollArrow] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({});
  const textSectionRef = useRef(null);

  const handleImageClick = (index) => {
    setActiveImageIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const scrollToGallery = () => {
    document.getElementById("videoAzulejoPorcelamico").scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const textSection = textSectionRef.current;
        const textSectionVisible = entries.some(
          (entry) => entry.target === textSection && entry.isIntersecting
        );
        setShowScrollArrow(textSectionVisible);
      },
      { threshold: 0.1 }
    );

    const textSection = textSectionRef.current;
    if (textSection) observer.observe(textSection);

    return () => {
      if (textSection) observer.unobserve(textSection);
    };
  }, []);

  useEffect(() => {
    const isMobile = window.innerWidth <= 576; // Detecta si es un dispositivo móvil
    if (isMobile) {
      const h1Element = document.querySelector(".about-title");
      if (h1Element) {
        const spacer = document.createElement("div"); // Crear un div para el espacio
        spacer.style.height = "0.5px"; // Ajustar la altura deseada (por ejemplo, la mitad del tamaño estándar)
        h1Element.parentNode.insertBefore(spacer, h1Element); // Insertar el div antes del <h1>
      }
    }
  }, []);

  const handleChange = (e) => {
    const sanitizedValue = DOMPurify.sanitize(e.target.value.trim());
    setFormData({
      ...formData,
      [e.target.name]: sanitizedValue,
    });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validateFields = () => {
    const tempErrors = {};

    if (!formData.name.trim()) {
      tempErrors.name = "El nombre es obligatorio.";
    } else if (formData.name.trim().length < 3) {
      tempErrors.name = "El nombre debe tener al menos 3 caracteres.";
    }

    if (!formData.email.trim()) {
      tempErrors.email = "El correo electrónico es obligatorio.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Por favor, introduce un correo electrónico válido.";
    }

    if (!formData.phone.trim()) {
      tempErrors.phone = "El teléfono es obligatorio.";
    } else if (!/^(\+34\s?)?6\d{8}$/.test(formData.phone)) {
      tempErrors.phone =
        "Por favor, introduce un número de móvil español válido (con o sin prefijo +34).";
    }

    if (!formData.message.trim()) {
      tempErrors.message = "El mensaje es obligatorio.";
    } else if (formData.message.trim().length < 10) {
      tempErrors.message = "El mensaje debe tener al menos 10 caracteres.";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) return;

    try {
      const response = await fetch("/api/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage("¡Formulario enviado exitosamente!");
        setFormData({ name: "", email: "", phone: "", message: "" });
        setErrors({});
      } else {
        const errorText = await response.json();
        setErrorMessage(
          `Error: ${errorText.error ||
          "No se pudo enviar el formulario. Por favor, inténtelo más tarde."
          }`
        );
      }
    } catch (error) {
      setErrorMessage(
        "Error al conectar con el servidor. Por favor, inténtelo más tarde."
      );
    }
  };

  return (
    <main className="container-fluid my-5 text-center about-section">
      <Navbar onScroll={(sectionId) => document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" })} />
      <article className="container-fluid px-0">
        <header className="row mx-0 d-flex flex-column flex-md-row align-items-start">
          <div className="col-md-6 px-3 text-start d-flex align-items-start" ref={textSectionRef}>
            <div>
              <h1 className="about-title text-center text-md-start">
                Expertos en Piscinas de Azulejo Porcelámico en Cullar Vega, Granada
              </h1>
              <p className="about-subtitle text-center text-md-start fw-light mb-4">
                En <strong>Piscinas Jiménez Ureña</strong>, nos avalan más de <strong>30 años de experiencia en la construcción de piscinas de azulejo porcelámico</strong>, donde combinamos <strong>tecnología, artesanía y exclusividad</strong> para garantizar resultados de primera calidad. No solo construimos piscinas, <strong>creamos espacios de bienestar y elegancia</strong> que elevan la estética y el confort de cualquier hogar. Cada proyecto es único y se diseña con <strong>precisión, innovación y materiales de alto rendimiento</strong>. Sabemos que una piscina no es solo una inversión, sino un reflejo de <strong>tu estilo y personalidad</strong>, por eso cuidamos cada detalle para que se integre a la perfección en cualquier ambiente: <strong>moderno, rústico o vanguardista</strong>.
              </p>
              <p className="about-subtitle text-center text-md-start fw-light mb-4">
                En <strong>Cúllar Vega, Granada</strong>, hemos transformado jardines y espacios exteriores en auténticos <strong>oasis de relajación</strong>, convirtiéndonos en <strong>referentes en piscinas de azulejo porcelámico</strong>. Nos distingue nuestra <strong>atención al detalle</strong> y la <strong>satisfacción total de nuestros clientes</strong>, quienes confían en nuestra capacidad para ofrecerles una piscina con <strong>acabados impecables y durabilidad asegurada</strong>. No solo garantizamos un diseño espectacular, sino que también ofrecemos un producto que <strong>aumenta el valor de tu propiedad</strong>, asegurando una inversión inteligente con beneficios a largo plazo.
              </p>
              <p className="about-subtitle text-center text-md-start fw-light mb-4">
                Lo que nos diferencia es nuestra capacidad para <strong>fusionar la tradición con las tecnologías más avanzadas</strong>. Utilizamos <strong>azulejo porcelámico de última generación</strong>, un material que destaca por su <strong>baja porosidad</strong>, evitando la absorción de agua y minimizando la aparición de bacterias y hongos. ¿Qué ventajas tiene esto? <strong>Mantenimiento prácticamente nulo</strong>, agua siempre <strong>pura y cristalina</strong> y una piscina que se conserva en perfecto estado con el mínimo esfuerzo. Si buscas <strong>calidad, eficiencia y comodidad</strong>, nuestras piscinas son la elección ideal.
              </p>
              <p className="about-subtitle text-center text-md-start fw-light mb-4">
                <strong>¿Quieres una piscina exclusiva, diseñada a tu medida?</strong> En <strong>Piscinas Jiménez Ureña</strong>, no seguimos moldes estándar, sino que creamos <strong>proyectos personalizados</strong> adaptados a cada cliente. Desde la <strong>elección de los mejores materiales</strong> hasta la <strong>ejecución final</strong>, nuestro equipo te guiará en cada etapa del proceso para asegurar que el resultado supere todas tus expectativas. No importa el tamaño ni la complejidad del diseño, nuestra misión es transformar cualquier espacio en un <strong>oasis de lujo, confort y distinción</strong>.
              </p>
              <p className="about-subtitle text-center text-md-start fw-light mb-4">
                Si buscas <strong>piscinas de azulejo porcelámico en Granada, Málaga y Jaén</strong>, <strong>Piscinas Jiménez Ureña</strong> es la mejor elección. Nuestra experiencia, compromiso y atención al detalle nos convierten en la opción más fiable para quienes buscan un resultado perfecto. Desde el primer contacto hasta la entrega final, supervisamos cada fase para que el proyecto cumpla con los estándares más exigentes. <strong>Solicita tu presupuesto sin compromiso</strong> y empieza a disfrutar de la piscina con la que siempre has soñado, con la tranquilidad de contar con los mejores especialistas del sector.
              </p>
              <video id="videoAzulejoPorcelamico" className="w-100 mt-4" controls>
                <source src={azulejoPorcelamicoVideo} type="video/mp4" />
                Tu navegador no soporta la reproducción de videos.
              </video>
              {showScrollArrow && (
                <div className="scroll-arrow-wrapper" onClick={scrollToGallery}>
                  <span className="scroll-text">Ver Video y Fotos</span>
                  <span className="scroll-arrow">⬇</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-start">

            <div id="gallery" className="col-md-12">
              <div className="gallery-container">
                {images.map((image, index) => (
                  <div key={index} className="gallery-item" onClick={() => handleImageClick(index)}>
                    <img
                      src={image}
                      alt={`Piscina de Hormigón - Imagen ${index + 1}`}
                      className="gallery-image"
                      loading="lazy"
                    />
                    <div className="gallery-overlay">
                      <button className="btn btn-primary">Hacer Zoom</button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </header>
      </article>
      <section id="getInTouch" className="container-fluid mt-5">
        <form onSubmit={handleSubmit} className="contact-form w-100">
          <h3 className="form-title">Formulario de Contacto</h3>
          {successMessage && <p className="success-message">{successMessage}</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div className="form-group">
            <label htmlFor="name">Nombre</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Correo Electrónico</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Teléfono</label>
            <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Mensaje</label>
            <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
          </div>
          <button type="submit" className="submit-btn slide-in">Enviar mensaje</button>
        </form>
      </section>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="modal-overlay"
        contentLabel="Image Gallery"
        ariaHideApp={false}
      >
        <div className="modal-content">
          <Swiper
            initialSlide={activeImageIndex}
            modules={[Navigation, Pagination, Zoom]}
            navigation
            pagination={{ clickable: true }}
            zoom={true}
            spaceBetween={10}
            slidesPerView={1}
            style={{ height: "100%", width: "100%" }}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="swiper-zoom-container" style={{ height: "100%" }}>
                  <img
                    src={image}
                    alt={`Piscina de Hormigón ${index + 1}`}
                    className="swiper-image"
                    loading="lazy"
                    style={{ width: "100%", maxHeight: "100vh", objectFit: "fill" }}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <button onClick={closeModal} className="close-modal-btn">Cerrar</button>
        </div>
      </Modal>
    </main>
  );
};

export default CullarVegaSection;