import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../NavBar/Navbar";
import Modal from "react-modal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Zoom } from "swiper/modules";
import DOMPurify from "dompurify";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "./style/AzulejoPorcelamicoSection.css";

import privatePoolAzulejoImage1 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinaUno.jpeg";
import privatePoolAzulejoImage2 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinaDos.jpeg";
import privatePoolAzulejoImage3 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinaTres.jpeg";
import privatePoolAzulejoImage4 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinaCuatro.jpeg";
import privatePoolAzulejoImage5 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasCinco.jpeg";
import privatePoolAzulejoImage6 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinaSeis.jpeg";
import privatePoolAzulejoImage7 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasSiete.jpeg";
import privatePoolAzulejoImage9 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasNueve.jpeg";
import privatePoolAzulejoImage10 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasDiez.jpeg";
import privatePoolAzulejoImage11 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasOnce.jpeg";
import privatePoolAzulejoImage12 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasDoce.jpeg";
import privatePoolAzulejoImage13 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasTrece.jpeg";
import privatePoolAzulejoImage14 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasCatorce.jpeg";
import privatePoolAzulejoImage15 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasQuince.jpeg";
import privatePoolAzulejoImage16 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/pisicinasDieciseis.jpeg";
import privatePoolAzulejoImage17 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasDiecisiete.jpeg";
import privatePoolAzulejoImage18 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/pisicinasDieciocho.jpeg";
import privatePoolAzulejoImage19 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasDiecinueve.jpeg";
import privatePoolAzulejoImage20 from "../../../assets/images/piscinas/private-pool/piscinas-azulejo-porcelamico/piscinasVeinte.jpeg";

import azulejoPorcelamicoVideo from "../../../assets/videos/videos-piscinas-azulejo-porcelamico/azulejoPorcelamico.mp4";

const images = [
  privatePoolAzulejoImage1,
  privatePoolAzulejoImage2,
  privatePoolAzulejoImage3,
  privatePoolAzulejoImage4,
  privatePoolAzulejoImage5,
  privatePoolAzulejoImage6,
  privatePoolAzulejoImage7,
  privatePoolAzulejoImage9,
  privatePoolAzulejoImage10,
  privatePoolAzulejoImage11,
  privatePoolAzulejoImage12,
  privatePoolAzulejoImage13,
  privatePoolAzulejoImage14,
  privatePoolAzulejoImage15,
  privatePoolAzulejoImage16,
  privatePoolAzulejoImage17,
  privatePoolAzulejoImage18,
  privatePoolAzulejoImage19,
  privatePoolAzulejoImage20,
];

const AzulejoPorcelamicoSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [showScrollArrow, setShowScrollArrow] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({});
  const textSectionRef = useRef(null);

  const handleImageClick = (index) => {
    setActiveImageIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const scrollToGallery = () => {
    document.getElementById("videoAzulejoPorcelamico").scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const textSection = textSectionRef.current;
        const textSectionVisible = entries.some(
          (entry) => entry.target === textSection && entry.isIntersecting
        );
        setShowScrollArrow(textSectionVisible);
      },
      { threshold: 0.1 }
    );

    const textSection = textSectionRef.current;
    if (textSection) observer.observe(textSection);

    return () => {
      if (textSection) observer.unobserve(textSection);
    };
  }, []);

  useEffect(() => {
    const isMobile = window.innerWidth <= 576; // Detecta si es un dispositivo móvil
    if (isMobile) {
      const h1Element = document.querySelector(".about-title");
      if (h1Element) {
        const spacer = document.createElement("div"); // Crear un div para el espacio
        spacer.style.height = "0.5px"; // Ajustar la altura deseada (por ejemplo, la mitad del tamaño estándar)
        h1Element.parentNode.insertBefore(spacer, h1Element); // Insertar el div antes del <h1>
      }
    }
  }, []);
  
  const handleChange = (e) => {
    const sanitizedValue = DOMPurify.sanitize(e.target.value.trim());
    setFormData({
      ...formData,
      [e.target.name]: sanitizedValue,
    });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validateFields = () => {
    const tempErrors = {};

    if (!formData.name.trim()) {
      tempErrors.name = "El nombre es obligatorio.";
    } else if (formData.name.trim().length < 3) {
      tempErrors.name = "El nombre debe tener al menos 3 caracteres.";
    }

    if (!formData.email.trim()) {
      tempErrors.email = "El correo electrónico es obligatorio.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Por favor, introduce un correo electrónico válido.";
    }

    if (!formData.phone.trim()) {
      tempErrors.phone = "El teléfono es obligatorio.";
    } else if (!/^(\+34\s?)?6\d{8}$/.test(formData.phone)) {
      tempErrors.phone =
        "Por favor, introduce un número de móvil español válido (con o sin prefijo +34).";
    }

    if (!formData.message.trim()) {
      tempErrors.message = "El mensaje es obligatorio.";
    } else if (formData.message.trim().length < 10) {
      tempErrors.message = "El mensaje debe tener al menos 10 caracteres.";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) return;

    try {
      const response = await fetch("/api/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage("¡Formulario enviado exitosamente!");
        setFormData({ name: "", email: "", phone: "", message: "" });
        setErrors({});
      } else {
        const errorText = await response.json();
        setErrorMessage(
          `Error: ${errorText.error ||
          "No se pudo enviar el formulario. Por favor, inténtelo más tarde."
          }`
        );
      }
    } catch (error) {
      setErrorMessage(
        "Error al conectar con el servidor. Por favor, inténtelo más tarde."
      );
    }
  };

  return (
    <main className="container-fluid my-5 text-center about-section">
      <Navbar onScroll={(sectionId) => document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" })} />
      <article className="container-fluid px-0">
        <header className="row mx-0 d-flex flex-column flex-md-row align-items-start">
          <div className="col-md-6 px-3 text-start d-flex align-items-start" ref={textSectionRef}>
            <div>
              <h1 className="about-title text-center text-md-start">
                Expertos en Piscinas de Azulejo Porcelámico en Granada, Málaga y Jaén
              </h1>
              <p className="about-subtitle text-center text-md-start fw-light mb-4">
                En <strong>Piscinas Jiménez Ureña</strong>, nos especializamos en la <strong>construcción de piscinas de azulejo porcelámico</strong>. Este tipo de revestimiento no solo ofrece una apariencia lujosa y sofisticada, sino que también destaca por su <strong>durabilidad extrema</strong> y <strong>resistencia a las condiciones climáticas</strong>. Gracias a su diseño versátil, estas piscinas se adaptan perfectamente a cualquier entorno, ya sea moderno, minimalista o clásico.
              </p>
              <p className="about-subtitle text-center text-md-start fw-light mb-4">
                Con más de 30 años de experiencia en el sector, en <strong>Piscinas Jiménez Ureña</strong> utilizamos materiales de la más alta calidad para garantizar <strong>acabados impecables</strong> y una estética que se mantiene impecable con el tiempo. Nuestras piscinas de azulejo porcelámico no solo embellecen el espacio, sino que también aumentan el valor de tu propiedad.
              </p>
              <p className="about-subtitle text-center text-md-start fw-light mb-4">
                Este tipo de piscinas destaca por su <strong>baja porosidad</strong>, lo que impide la absorción de agua y evita la proliferación de bacterias y hongos. Además, su <strong>mantenimiento es mínimo</strong>, permitiéndote disfrutar de un espacio siempre limpio y reluciente con poco esfuerzo. 
              </p>
              <p className="about-subtitle text-center text-md-start fw-light mb-4">
              <strong>¿Buscas un diseño exclusivo y a medida? </strong>Nuestro equipo de expertos te asesora en cada paso del proceso, desde la elección de los materiales hasta la implementación del diseño ideal para tu hogar o negocio. Convertimos cualquier espacio en un oasis personalizado, combinando innovación y estética de vanguardia.
              </p>
              <p className="about-subtitle text-center text-md-start fw-light mb-4">
                Si deseas más información sobre nuestras <strong>piscinas de azulejo porcelámico en Granada, Málaga y Jaén</strong>, no dudes en contactarnos. En <strong>Piscinas Jiménez Ureña</strong>, nos apasiona crear ambientes únicos que reflejen tu estilo de vida. ¡Solicita tu presupuesto sin compromiso y da el primer paso hacia la piscina de tus sueños!
              </p>
              <video id="videoAzulejoPorcelamico" className="w-100 mt-4" controls>
                <source src={azulejoPorcelamicoVideo} type="video/mp4" />
                Tu navegador no soporta la reproducción de videos.
              </video>
              {showScrollArrow && (
              <div className="scroll-arrow-wrapper" onClick={scrollToGallery}>
                <span className="scroll-text">Ver Video y Fotos</span>
                <span className="scroll-arrow">⬇</span>
              </div>
            )}
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-start">
            
          <div id="gallery" className="col-md-12">
            <div className="gallery-container">
              {images.map((image, index) => (
                <div key={index} className="gallery-item" onClick={() => handleImageClick(index)}>
                  <img
                    src={image}
                    alt={`Piscina de Hormigón - Imagen ${index + 1}`}
                    className="gallery-image"
                    loading="lazy"
                  />
                  <div className="gallery-overlay">
                    <button className="btn btn-primary">Hacer Zoom</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          </div>
        </header>
      </article>
      <section id="getInTouch" className="container-fluid mt-5">
        <form onSubmit={handleSubmit} className="contact-form w-100">
          <h3 className="form-title">Formulario de Contacto</h3>
          {successMessage && <p className="success-message">{successMessage}</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div className="form-group">
            <label htmlFor="name">Nombre</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Correo Electrónico</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Teléfono</label>
            <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Mensaje</label>
            <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
          </div>
          <button type="submit" className="submit-btn slide-in">Enviar mensaje</button>
        </form>
      </section>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="modal-overlay"
        contentLabel="Image Gallery"
        ariaHideApp={false}
      >
        <div className="modal-content">
          <Swiper
            initialSlide={activeImageIndex}
            modules={[Navigation, Pagination, Zoom]}
            navigation
            pagination={{ clickable: true }}
            zoom={true}
            spaceBetween={10}
            slidesPerView={1}
            style={{ height: "100%", width: "100%" }}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="swiper-zoom-container" style={{ height: "100%" }}>
                  <img
                    src={image}
                    alt={`Piscina de Hormigón ${index + 1}`}
                    className="swiper-image"
                    loading="lazy"
                    style={{ width: "100%", maxHeight: "100vh", objectFit: "fill" }}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <button onClick={closeModal} className="close-modal-btn">Cerrar</button>
        </div>
      </Modal>
    </main>
  );
};

export default AzulejoPorcelamicoSection;