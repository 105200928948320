import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './style/NavBar.css';

const Navbar = ({ onScroll }) => {
  const collapseRef = useRef(null);
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    if (collapseRef.current && collapseRef.current.classList.contains('show')) {
      collapseRef.current.classList.remove('show');
    }
  };

  return (
    <header className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm enhanced-navbar">
      <div className="container">
        <a href="/" className="navbar-brand">
          <span className="brand-desktop">Piscinas Jiménez Ureña</span>
          <span className="brand-mobile">Piscinas J. Ureña</span>
        </a>


        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse justify-content-end" id="navbarNav" ref={collapseRef}>
          <ul className="navbar-nav">
            <li className="nav-item">
              <button className="nav-link btn btn-link" onClick={() => handleNavigation('/')}>Inicio</button>
            </li>
            <li className="nav-item">
              <button className="nav-link btn btn-link" onClick={() => handleNavigation('/empresa')}>Empresa</button>
            </li>
            <li className="nav-item dropdown">
              <button
                className="nav-link dropdown-toggle btn btn-link"
                id="servicesDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Servicios
              </button>
              <ul className="dropdown-menu" aria-labelledby="servicesDropdown">
                <li>
                  <button className="dropdown-item btn btn-link" onClick={() => handleNavigation('/piscinas-azulejo-porcelamico')}>Piscinas Azulejo Porcelámico</button>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <button
                className="nav-link dropdown-toggle btn btn-link"
                id="servicesDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Últimos Proyectos
              </button>
              <ul className="dropdown-menu" aria-labelledby="servicesDropdown">
                <li>
                  <button className="dropdown-item btn btn-link" onClick={() => handleNavigation('/piscinas-cullar-vega')}>Piscina Cúllar Vega</button>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <button className="nav-link btn btn-link" onClick={() => handleNavigation('/contacto')}>Contacto</button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
